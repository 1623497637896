<template>
  <div class="list-sport-matches">
    <div
      class="list-sport-matches__item"
      v-for="item in items"
      :key="item.id"
      ref="itemsRef"
      @click="goToSportEvent(item)"
    >
      <div class="list-sport-matches__row">
        <div class="list-sport-matches__info">
          <atomic-image :src="getSrc(item)" class="list-sport-matches__info-image" />

          <div class="list-sport-matches__text">{{ name(item) }}</div>
        </div>

        <div class="list-sport-matches__arrow">
          <atomic-icon id="arrow_next" />
        </div>
      </div>

      <div class="list-sport-matches__row">
        <div class="list-sport-matches__event">
          <div class="list-sport-matches__text">{{ getEventTeams(item.name)[0] }}</div>

          <div class="list-sport-matches__text">-</div>

          <div class="list-sport-matches__text">{{ getEventTeams(item.name)[1] }}</div>
        </div>

        <div class="list-sport-matches__date">{{ formatEventDate(item.eventDate) }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { IPaginationMeta, ISportEvent } from '@skeleton/core/types';

  const props = defineProps<{
    items: ISportEvent[];
    meta: IPaginationMeta;
  }>();

  const emit = defineEmits<{
    (event: 'loadMore'): void;
  }>();

  const { initObserver } = useProjectMethods();

  const {
    public: { gamehubCdn },
  } = useRuntimeConfig();

  const dayjs = useDayjs();

  const router = useRouter();

  const loadMoreObserver = ref();
  const itemsRef = ref([]);
  const lastItem = ref();

  const formatEventDate = (date: string) => {
    return dayjs(date).utc().format('DD/MM • HH:mm'); // Формат: 05/11 • 22:00
  };

  const name = ({ championship }: ISportEvent): string => {
    const { category } = championship;
    return `${category.name}. ${championship.name}`;
  };

  const getEventTeams = (name: string): [string, string] => {
    const [first = '', second = ''] = name.split('vs.');
    return [first.trim(), second.trim()];
  };

  const getSrc = ({ championship }: ISportEvent): string => {
    const { category } = championship;
    return `${gamehubCdn}${category.sport.image}`;
  };

  const goToSportEvent = (item: { id: number }) => {
    router.push(`/sport#/event/${item.id}`);
  };

  const observerLoadMore = (): void => {
    if (props.meta.totalPages > props.meta.page) emit('loadMore');
  };

  const unobserveLastItem = (): void => {
    if (lastItem.value) {
      loadMoreObserver.value.unobserve(lastItem.value);
      lastItem.value.removeEventListener('inview', observerLoadMore);
    }
  };

  const observeLastItem = (): void => {
    unobserveLastItem();

    lastItem.value = itemsRef.value[itemsRef.value?.length - 1];

    if (lastItem.value) {
      loadMoreObserver.value.observe(lastItem.value);
      lastItem.value.addEventListener('inview', observerLoadMore);
    }
  };

  watch(
    () => props.items,
    async () => {
      await nextTick();
      observeLastItem();
    }
  );

  onMounted(() => {
    loadMoreObserver.value = initObserver({
      settings: { root: null, rootMargin: '100% 0% 100% 0%', threshold: 0 },
    });
    observeLastItem();
  });

  onBeforeUnmount(() => {
    unobserveLastItem();
  });
</script>

<style src="~/assets/styles/components/list/sport-matches.scss" lang="scss" />
