<template>
  <div class="global-search-games-section global-search-games-section--type-sports">
    <div class="global-search-games-section__header">
      <div class="global-search-games-section__header-title">
        {{ getContent(popupsData, defaultLocalePopupsData, 'globalSearch.typeSports') }}
      </div>

      <div class="global-search-games-section__header-count">{{ items.length || '' }}</div>
    </div>

    <list-skeleton-preloader v-if="loading" :initialGamesCount="initialGamesCount" />

    <list-type-sports v-else :items="items" />
  </div>
</template>

<script setup lang="ts">
  import type { ISport } from '@skeleton/core/types';

  const props = defineProps<{
    items: ISport[];
    loading: boolean;
    isMobile: boolean;
  }>();

  const { getContent } = useProjectMethods();
  const { popupsData, defaultLocalePopupsData } = storeToRefs(useGlobalStore());

  const initialGamesCount = computed(() => (props.isMobile ? 5 : 10));
</script>

<style src="~/assets/styles/components/modal-content/global-search-games.scss" lang="scss" />
