<template>
  <div class="global-search-games-section" v-if="queries.length">
    <div class="global-search-games-section__header">
      <div class="global-search-games-section__header-title">
        {{ getContent(popupsData, defaultLocalePopupsData, 'globalSearch.recentSearches') }}
      </div>

      <div class="global-search-games-section__header-clear" @click="onClearAll">
        {{ getContent(popupsData, defaultLocalePopupsData, 'globalSearch.clearAll') }}
      </div>
    </div>

    <list-recent-searches :items="queries" @onSelectItem="onSelectItem" />
  </div>
</template>

<script setup lang="ts">
  import type { ISearchRecentRequest } from '@skeleton/core/types';

  const props = defineProps<{
    params: ISearchRecentRequest;
  }>();

  const emits = defineEmits<{
    (event: 'onSelectItem', value: string): void;
  }>();

  const { getContent } = useProjectMethods();
  const { popupsData, defaultLocalePopupsData } = storeToRefs(useGlobalStore());
  const globalSearchStore = useGlobalSearchStore();
  const { queries } = storeToRefs(globalSearchStore);

  const onSelectItem = (item: string): void => {
    emits('onSelectItem', item);
  };

  const onClearAll = () => {
    globalSearchStore.goToDeleteSearchRecent(props.params);
  };

  onMounted(() => {
    globalSearchStore.goToLoadSearchRecent(props.params);
  });
</script>

<style src="~/assets/styles/components/modal-content/global-search-games.scss" lang="scss" />
