<template>
  <div class="global-search-games-section">
    <div class="global-search-games-section__header">
      <div class="global-search-games-section__header-title">
        {{ getContent(popupsData, defaultLocalePopupsData, 'globalSearch.games') }}
      </div>

      <div class="global-search-games-section__header-count">{{ meta?.totalRows }}</div>
    </div>

    <list-skeleton-preloader v-if="loading" :initialGamesCount="11" />

    <list-grid v-else :items="items" :meta="meta" @load-more="loadMoreItems" />
  </div>
</template>

<script setup lang="ts">
  import type { IGame, IPaginationMeta } from '@skeleton/core/types';

  defineProps<{
    items: IGame[];
    meta: IPaginationMeta | null;
    loading: boolean;
  }>();

  const emits = defineEmits<{
    (event: 'loadMoreItems'): void;
  }>();

  const { getContent } = useProjectMethods();
  const { popupsData, defaultLocalePopupsData } = storeToRefs(useGlobalStore());

  const loadMoreItems = (): void => {
    emits('loadMoreItems');
  };
</script>

<style src="~/assets/styles/components/modal-content/global-search-games.scss" lang="scss" />
