import { defineStore } from 'pinia';

import type { ISearchRecentRequest } from '@skeleton/core/types';

interface ISearchRecentStoreState {
  queries: string[];
}

export const useGlobalSearchStore = defineStore('globalSearchStore', {
  state: (): ISearchRecentStoreState => ({
    queries: [],
  }),

  actions: {
    setQueries(payload: string[]): void {
      this.queries = payload;
    },

    updateQueries(value: string): void {
      this.queries = [value, ...this.queries.filter(query => query !== value)];
    },

    async goToLoadSearchRecent(params: ISearchRecentRequest): Promise<void> {
      const { getSearchRecent } = useCoreGamesApi();

      try {
        const response = await getSearchRecent(params);
        const { data } = response;
        const { queries } = data;
        this.setQueries(queries);
      } catch (error) {
        console.error(error);
      }
    },

    async goToDeleteSearchRecent(body: ISearchRecentRequest): Promise<void> {
      const { deleteSearchRecent } = useCoreGamesApi();

      try {
        await deleteSearchRecent(body);
        this.setQueries([]);
      } catch (error) {
        console.error(error);
      }
    },
  },
});
