<template>
  <div class="global-search-games">
    <template v-if="isSearching">
      <modal-content-global-search-type-sports-section
        v-if="hasSports"
        :items="sportsItems"
        :loading="loading"
        :isMobile="isMobile"
      />

      <modal-content-global-search-matches-sports-section
        v-if="hasEvents"
        :items="eventsItems"
        :meta="pageMeta"
        :loading="loading"
        @loadMoreItems="loadMoreItems"
      />
    </template>

    <atomic-empty v-if="isNotFound" :title="emptyTitle" :subTitle="emptyDescription" variant="not-found" />

    <modal-content-global-search-recent-searches-section
      v-if="isLoggedIn"
      v-show="showRecentSearchesSection"
      :params="params"
      @onSelectItem="onSelectItem"
    />

    <modal-content-global-search-sports-like-section
      v-show="showLikeSection"
      :emptyTitle="emptyTitle"
      :emptyDescription="emptyDescription"
      :hasSearchQuery="hasSearchQuery"
    />
  </div>
</template>

<script setup lang="ts">
  import { SortByFields, SortOrders } from '@skeleton/consts/sort';

  import type {
    IGameSearchRequest,
    ISportsResponse,
    IPaginationMeta,
    ISearchRecentRequest,
    ISportEvent,
    ISport,
  } from '@skeleton/core/types';
  import type { IGamesPage } from '~/types';

  const props = defineProps({
    searchValue: {
      type: String,
      default: '',
    },
  });

  const emits = defineEmits<{
    (event: 'updateSearchValue', value: string): void;
  }>();

  const { getSearchSport } = useCoreGamesApi();

  const contentParams = {
    contentKey: 'gamesPageContent',
    contentRoute: ['pages', 'games'],
    isPage: true,
  };
  const { getContentData } = useContentLogic<IGamesPage>(contentParams);
  const { data: gamesContent } = await useLazyAsyncData(getContentData);
  const { updateQueries } = useGlobalSearchStore();
  const { getContent } = useProjectMethods();
  const { isLoggedIn } = storeToRefs(useProfileStore());
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const params = { type: 'sport' } as ISearchRecentRequest;

  const loading = ref(false);
  const loadPage = ref(1);
  const pageMeta = ref<IPaginationMeta | null>(null);
  const sportsItems = ref<ISport[]>([]);
  const eventsItems = ref<ISportEvent[]>([]);

  const itemsPerPage = computed(() => (isMobile.value ? 20 : 40));

  const defaultRequestParams = computed<IGameSearchRequest>(() => ({
    page: loadPage.value,
    perPage: itemsPerPage.value,
    query: props.searchValue || undefined,
    sortBy: SortByFields.NAME,
    sortOrder: SortOrders.ASC.toUpperCase(),
  }));

  const hasEvents = computed(() => eventsItems.value.length > 0 || loading.value);
  const hasSports = computed(() => sportsItems.value.length > 0 || loading.value);

  const hasSearchQuery = computed(() => !!props.searchValue && props.searchValue.length > 1);
  const isNotFound = computed(() => hasSearchQuery.value && !loading.value && !hasSports.value && !hasEvents.value);
  const isSearching = computed(() => hasSearchQuery.value && !isNotFound.value);

  const showRecentSearchesSection = computed(() => !hasSearchQuery.value);
  const showLikeSection = computed(() => !hasSearchQuery.value || isNotFound.value);

  const emptyTitle = computed(() =>
    getContent(gamesContent?.value?.currentLocaleData, gamesContent?.value?.defaultLocaleData, 'empty.title')
  );

  const emptyDescription = computed(() =>
    getContent(gamesContent?.value?.currentLocaleData, gamesContent?.value?.defaultLocaleData, 'empty.description')
  );

  const onSelectItem = (item: string): void => {
    emits('updateSearchValue', item);
  };

  const changePage = (newPage: number = 1): void => {
    loadPage.value = newPage;
  };

  const loadMoreItems = async (): Promise<void> => {
    const newPage = (pageMeta.value?.page || 0) + 1;
    await changePage(newPage);
    await loadSports(true);
  };

  const resetSports = (): ISportsResponse => ({
    data: {
      sports: [],
      events: [],
    },
    meta: {
      perPage: itemsPerPage.value,
      page: 1,
      totalPages: 0,
      totalRows: 0,
    },
  });

  const setSports = (response: ISportsResponse, append = false): void => {
    const { data, meta } = response;
    const { sports, events = [] } = data;

    eventsItems.value = append ? [...eventsItems.value, ...events] : events;
    sportsItems.value = sports;
    pageMeta.value = meta;
  };

  const loadSports = async (more = false): Promise<void> => {
    if (!props.searchValue) {
      setSports(resetSports());
      return;
    }

    try {
      const response = await getSearchSport(defaultRequestParams.value);
      setSports(response, more);

      if (isLoggedIn.value && (response.data.sports.length || response.data.events.length)) {
        updateQueries(props.searchValue);
      }
    } catch (error) {
      console.error(error);
    }

    loading.value = false;
  };

  watch(
    () => props.searchValue,
    () => {
      if (hasSearchQuery.value) {
        loading.value = true;
        changePage(1);
        loadSports(false);
      }
    },
    { immediate: true }
  );
</script>

<style src="~/assets/styles/components/modal-content/global-search-games.scss" lang="scss" />
