<template>
  <div class="global-search-games-section">
    <div class="global-search-games-section__header">
      <div class="global-search-games-section__header-title">
        {{ getContent(popupsData, defaultLocalePopupsData, 'globalSearch.gamesMightLike') }}
      </div>
    </div>

    <list-skeleton-preloader v-if="loadingGames" :initialGamesCount="initialGamesCountSkeleton" />

    <list-grid v-else :items="gameItems" :meta="pageMeta" @load-more="loadMoreItems" />
  </div>
</template>

<script setup lang="ts">
  import type { IGame, IGamesRequest, IPaginationMeta } from '@skeleton/core/types';

  import { SortByFields, SortOrders } from '@skeleton/consts/sort';

  const { getContent } = useProjectMethods();
  const globalStore = useGlobalStore();
  const { popupsData, defaultLocalePopupsData } = storeToRefs(globalStore);
  const { getGames } = useGameCollectionStoreStore();
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const loadPage = ref(1);
  const gameItems = ref<IGame[]>([]);
  const pageMeta = ref<IPaginationMeta>();
  const loadingGames = ref(false);

  const initialGamesCountSkeleton = computed(() => (isMobile.value ? 9 : 10));
  const itemsPerPage = computed(() => (isMobile.value ? 27 : 40));

  const defaultRequestParams = computed<IGamesRequest>(() => {
    return {
      page: loadPage.value,
      perPage: itemsPerPage.value,
      sortBy: SortByFields.POPULARITY,
      sortOrder: SortOrders.ASC,
    };
  });

  const changePage = (newPage: number = 1): void => {
    loadPage.value = newPage;
  };

  const loadMoreItems = async (): Promise<void> => {
    const newPage = (pageMeta.value?.page || 0) + 1;
    await changePage(newPage);
    await loadGames(true);
  };

  const setItems = (data: any, more?: boolean) => {
    gameItems.value = more ? gameItems.value.concat(data.games) : data.games;
    pageMeta.value = data.pageMeta;
  };

  const loadGames = async (more = false) => {
    const data = await getGames(defaultRequestParams.value, true);
    setItems(data, more);

    loadingGames.value = false;
  };

  onMounted(() => {
    loadingGames.value = true;
    loadGames();
  });
</script>

<style src="~/assets/styles/components/modal-content/global-search-games.scss" lang="scss" />
