<template>
  <div class="global-search-games-section global-search-games-section--matches-sports">
    <atomic-empty v-if="isNotFound" :title="emptyTitle" :subTitle="emptyDescription" variant="not-found" />

    <div v-if="!loading && eventsItems.length" class="global-search-games-section__header">
      <div class="global-search-games-section__header-title">
        {{ getContent(popupsData, defaultLocalePopupsData, 'globalSearch.matches') }}
      </div>
    </div>

    <list-skeleton-preloader v-if="loading" :initialGamesCount="10" />

    <list-sport-matches v-else :items="eventsItems" :meta="pageMeta" @loadMore="loadMoreItems" />
  </div>
</template>

<script setup lang="ts">
  import type { IGamesRequest, IPaginationMeta, ISportEvent } from '@skeleton/core/types';

  import { SortByFields, SortOrders } from '@skeleton/consts/sort';

  const props = defineProps<{
    emptyTitle: string;
    emptyDescription: string;
    hasSearchQuery: boolean;
  }>();

  const { getSearchSport } = useCoreGamesApi();

  const { getContent } = useProjectMethods();
  const globalStore = useGlobalStore();
  const { popupsData, defaultLocalePopupsData } = storeToRefs(globalStore);
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const loadPage = ref(1);
  const eventsItems = ref<ISportEvent[]>([]);
  const pageMeta = ref<IPaginationMeta>();
  const loading = ref(false);

  const itemsPerPage = computed(() => (isMobile.value ? 20 : 40));

  const defaultRequestParams = computed<IGamesRequest>(() => {
    return {
      page: loadPage.value,
      perPage: itemsPerPage.value,
      sortBy: SortByFields.NAME,
      sortOrder: SortOrders.ASC.toUpperCase(),
    };
  });

  const isNotFound = computed(() => !loading.value && !eventsItems.value.length && !props.hasSearchQuery);

  const changePage = (newPage: number = 1): void => {
    loadPage.value = newPage;
  };

  const loadMoreItems = async (): Promise<void> => {
    const newPage = (pageMeta.value?.page || 0) + 1;
    await changePage(newPage);
    await loadSports(true);
  };

  const setSports = (response: any, more?: boolean) => {
    const { data, meta } = response;
    const { events = [] } = data;

    eventsItems.value = more ? [...eventsItems.value, ...events] : events;
    pageMeta.value = meta;
  };

  const loadSports = async (more = false) => {
    try {
      const response = await getSearchSport(defaultRequestParams.value);
      setSports(response, more);
    } catch (error) {
      console.error(error);
    }

    loading.value = false;
  };

  onMounted(() => {
    loading.value = true;
    loadSports();
  });
</script>

<style src="~/assets/styles/components/modal-content/global-search-games.scss" lang="scss" />
