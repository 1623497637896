<template>
  <div class="list-recent-searches">
    <div class="list-recent-searches__item" v-for="item in items" :key="item" @click="onSelectItem(item)">
      {{ item }}
    </div>
  </div>
</template>

<script setup lang="ts">
  defineProps<{
    items: string[];
  }>();

  const emits = defineEmits<{
    (event: 'onSelectItem', value: string): void;
  }>();

  const onSelectItem = (item: string): void => {
    emits('onSelectItem', item);
  };
</script>

<style src="~/assets/styles/components/list/recent-searches.scss" lang="scss" />
