<template>
  <div class="tab-global-search">
    <tab-simple
      v-model:value="currentTab"
      :items="Object.values(tabs)"
    />
  </div>
</template>

<script setup lang="ts">
import { GlobalSearchTabs } from "@skeleton/consts/tabs/global-search-tabs";

const currentTab = defineModel('modelValue', {
  type: String,
  default: 'default',
});

const { getContent } = useProjectMethods();
const { popupsData, defaultLocalePopupsData } = storeToRefs(useGlobalStore());

const tabs = computed(() => {
  return {
    games: {
      id: GlobalSearchTabs.GAMES,
      name: getContent(popupsData.value, defaultLocalePopupsData.value, 'globalSearch.games'),
    },
    sports: {
      id: GlobalSearchTabs.SPORTS,
      name: getContent(popupsData.value, defaultLocalePopupsData.value,'globalSearch.sports'),
    },
  }
})
</script>

<style lang="scss" src="~/assets/styles/components/tab/global-search.scss" />