<template>
  <div class="list-type-sports">
    <div class="list-type-sports__item" v-for="item in items" :key="item.id" @click="goToSport(item)">
      <div class="list-type-sports__icon">
        <atomic-image :src="`${gamehubCdn}${item.image}`" />
      </div>

      <div class="list-type-sports__name">{{ item.name }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { ISport } from '@skeleton/core/types';

  defineProps<{
    items: ISport[];
  }>();

  const {
    public: { gamehubCdn },
  } = useRuntimeConfig();

  const router = useRouter();

  const goToSport = (item: { id: number }) => {
    router.push(`/sport#/sport/${item.id}`);
  };
</script>

<style src="~/assets/styles/components/list/type-sports.scss" lang="scss" />
