<template>
  <div class="global-search-games-section global-search-games-section--matches-sports">
    <div class="global-search-games-section__header">
      <div class="global-search-games-section__header-title">
        {{ getContent(popupsData, defaultLocalePopupsData, 'globalSearch.matches') }}
      </div>

      <div class="global-search-games-section__header-count">{{ meta?.totalRows }}</div>
    </div>

    <list-skeleton-preloader v-if="loading" :initialGamesCount="10" />

    <list-sport-matches v-else :items="items" :meta="meta" @loadMore="loadMoreItems" />
  </div>
</template>

<script setup lang="ts">
  import type { IPaginationMeta, ISportEvent } from '@skeleton/core/types';

  defineProps<{
    items: ISportEvent[];
    meta: IPaginationMeta | null;
    loading: boolean;
  }>();

  const emits = defineEmits<{
    (event: 'loadMoreItems'): void;
  }>();

  const { getContent } = useProjectMethods();
  const { popupsData, defaultLocalePopupsData } = storeToRefs(useGlobalStore());

  const loadMoreItems = (): void => {
    emits('loadMoreItems');
  };
</script>

<style src="~/assets/styles/components/modal-content/global-search-games.scss" lang="scss" />
